import {ChainsAPIInstance} from "@/plugins/axios";
export const SET_ATTRIBUTE = "SET_ATTRIBUTE";

const state = {

}

const actions = {
  [SET_ATTRIBUTE]:async ()=>{
    const { data }  = await ChainsAPIInstance({url:'/attribute',method:'POST', data:{"code": "010000", "address": "0x3ddfa8ec3052539b6c9549f12cea2c295cff5296"}});
    return data;
  },
}

const mutations = {

}

const getters = {

}
export default {
  state,
  actions,
  mutations,
  getters
}
