<template>
  <a @click="dialogVisible = true" class="feedbackButton" :class="fixed?'fixedButton defaultButton blue':''">
    <img src="@/assets/img/feedback.svg" alt="">
    Give Feedback
  </a>
  <el-dialog
      v-model="dialogVisible"
      custom-class="newDefaultDialog"
      title="Feedback window"
  >
    <el-form :model="feedbackForm">
      <el-form-item label="Feedback">
        <el-input type="textarea" :rows="2" v-model="feedbackForm.text" placeholder="Enter your feedback" clearable />
      </el-form-item>
      <el-form-item label="Email">
        <el-input v-model="feedbackForm.email" placeholder="Your email" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" class="defaultButton blue" :disabled="feedbackForm.text===''||feedbackForm.email===''" @click="onSubmit">Submit</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  props:{
    fixed:{
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialogVisible: false,
    feedbackForm:{
      text: '',
      email: ''
    }
  }),
  methods:{
    async onSubmit(){
      let text = 'Email:' + this.feedbackForm.email + ', Feedback:' + this.feedbackForm.text;
      try {
        // eslint-disable-next-line no-undef
        sendFeedbackForVue(text)
            .then(responseData => {
              console.log(responseData);
              this.$message.success('Thanks for your feedback!');
              this.dialogVisible = false;
              this.feedbackForm.email = '';
              this.feedbackForm.text = '';
            })
            .catch(error => {
              console.error(error);
              this.$message.success('Thanks for your feedback!');
              this.dialogVisible = false;
              this.feedbackForm.email = '';
              this.feedbackForm.text = '';
            });
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }
}
</script>
