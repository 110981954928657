import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vue3GoogleLogin from 'vue3-google-login'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "./assets/app.scss";
const CLIENT_ID = "928740285144-gmn6flfm82pgbe3psev4qgj80lcki118.apps.googleusercontent.com";

createApp(App).use(store).use(vue3GoogleLogin,{clientId: CLIENT_ID}).use(router).use(ElementPlus).mount('#app')
