import {DefaultAPIInstance} from "@/plugins/axios";
import {GET_USER_INFO} from "@/store/modules/AuthModule";
export const GET_PORTFOLIOS = "GET_PORTFOLIOS";
export const CREATE_PORTFOLIO = "CREATE_PORTFOLIO";
export const UPDATE_PORTFOLIO = "UPDATE_PORTFOLIO";
export const UPDATE_PORTFOLIO_SETTINGS = "UPDATE_PORTFOLIO_SETTINGS";
export const UPDATE_MY_PORTFOLIOS = "UPDATE_MY_PORTFOLIOS";
export const DELETE_PORTFOLIO = "DELETE_PORTFOLIO";
export const ENABLE_PORTFOLIO = "ENABLE_PORTFOLIO";
export const DISABLE_PORTFOLIO = "DISABLE_PORTFOLIO";
export const ADD_ASSET_TO_PORTFOLIO = "ADD_ASSET_TO_PORTFOLIO";
export const DELETE_ASSET_FROM_PORTFOLIO = "DELETE_ASSET_FROM_PORTFOLIO";

const state = {
  my_portfolios:[]
}

const actions = {
  [GET_PORTFOLIOS]:async (store)=>{
    const { data }  = await DefaultAPIInstance({url:'/pv1/portfolio_tracker/default/list/',method:'GET'});
    store.commit(UPDATE_MY_PORTFOLIOS, data);
    return data;
  },
  // this is not needed
  // because by default if you dont have any portfolio default portfolio will be created for you
  [CREATE_PORTFOLIO]:async (store, form)=>{
    // const { data }  = await DefaultAPIInstance({url:'/pv1/portfolio_tracker/create/',method:'POST',data:form});
    const { data }  = await DefaultAPIInstance({url:'/pv1/portfolio_tracker/default/',method:'GET',data:form});
    store.dispatch(GET_PORTFOLIOS);
    return data;
  },
  [UPDATE_PORTFOLIO]:async (store, form)=>{
    const { data }  = await DefaultAPIInstance({url:'/pv1/portfolio_tracker/default/',method:'PUT',data:form});
    store.dispatch(GET_PORTFOLIOS);
    store.dispatch(GET_USER_INFO);
    return data;
  },
  [UPDATE_PORTFOLIO_SETTINGS]:async (store, form)=>{
    const { data }  = await DefaultAPIInstance({url:'/pv1/portfolio_tracker/default/',method:'PUT',data:form});
    store.dispatch(GET_PORTFOLIOS);
    store.dispatch(GET_USER_INFO);
    return data;
  },
  [ENABLE_PORTFOLIO]:async (store)=>{
    const { data }  = await DefaultAPIInstance({url:'/pv1/portfolio_tracker/default/enable/',method:'POST'});
    store.dispatch(GET_PORTFOLIOS);
    return data;
  },
  [DISABLE_PORTFOLIO]:async (store)=>{
    const { data }  = await DefaultAPIInstance({url:'/pv1/portfolio_tracker/default/disable/',method:'DELETE'});
    store.dispatch(GET_PORTFOLIOS);
    return data;
  },
  [DELETE_PORTFOLIO]:async (store)=>{
    const { data }  = await DefaultAPIInstance({url:'/pv1/portfolio_tracker/default/',method:'DELETE'});
    store.dispatch(GET_PORTFOLIOS);
    return data;
  },
  [ADD_ASSET_TO_PORTFOLIO]:async (store, asset_data)=>{
    const { data }  = await DefaultAPIInstance({url:'/pv1/portfolio_tracker/default/add-asset/?name=name&chain_id=ethereum&asset_id='+asset_data.asset_id,
      method:'POST'});
    store.dispatch(GET_PORTFOLIOS);
    return data;
  },
  // there are 2 ways to delete asset from portfolio
  [DELETE_ASSET_FROM_PORTFOLIO]:async (store, asset_id)=>{
    const { data }  = await DefaultAPIInstance({url:'/pv1/portfolio_tracker/default/delete-asset/?name=name&chain_id=ethereum&asset_id=' + asset_id,method:'DELETE'});
    store.dispatch(GET_PORTFOLIOS);
    return data;
  },
  // [DELETE_ASSET_FROM_PORTFOLIO_USING_ID]:async (store, tracked_asset_uuid)=>{
  //   // TODO FIX IT
  //   const { data }  = await DefaultAPIInstance({url:'/api/pv1/portfolio_tracker/default/delete-asset/?id=' + tracked_asset_uuid,method:'DELETE'});
  //   store.dispatch(GET_PORTFOLIOS);
  //   return data;
  // },
}

const mutations = {
  [UPDATE_MY_PORTFOLIOS]: (state, data) => {
    state.my_portfolios = data;
  },
}

const getters = {
  get_my_portfolios: (state) => state.my_portfolios
}
export default {
  state,
  actions,
  mutations,
  getters
}
