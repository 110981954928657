<template>
  <div class="searchBlock" :class="for_top_mobile?'forTopMobile':''">
    <el-form :rules="rules" :model="form" ref="form" id="searchForm" @submit.prevent="onSearch">
      <el-form-item prop="search_input">
        <el-input v-model="form.search_input" @input="handleInput">
          <template #prepend v-if="form.search_input.length <= 0">
            <div class="placeholdersParent">
              <p class="animationPlaceholder elem1" id="search_text_1">Bitcoin | 34xp4vRoCGJym3xR7yCVPFHoCNxv4Twseo</p>
              <p class="animationPlaceholder elem2" id="search_text_2">Ethereum | 0x3ddfa8ec3052539b6c9549f12cea2c295cff5296</p>
              <p class="animationPlaceholder elem3" id="search_text_3">Polygon | 0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270</p>
              <p class="animationPlaceholder elem4" id="search_text_4">BNB Chain | 0xf977814e90da44bfa03b6295a0616a897441acec</p>
              <p class="animationPlaceholder elem5" id="search_text_5">ENS | vitalik.eth</p>
            </div>
          </template>
          <template #append>
            <el-button type="primary" :disabled="form.search_input.length <= 0" @click="onSearch"><i class="bi-search"></i></el-button>
          </template>
        </el-input>
      </el-form-item>
      <a v-if="from_home_page" href="/search/address/0x3ddfa8ec3052539b6c9549f12cea2c295cff5296" class="viewExample" onclick="sendMetric('viewexample');">View example</a>
    </el-form>
    <div class="searchResultList" :class="this.show_result?'opened':''" v-loading="loading">
      <div class="overflowBlock">
        <template v-if="this.chains_result_list.length>0">
          <p>Chains</p>
          <a :href="'/chain/'+item.slug" v-for="item in this.chains_result_list" v-bind:key="item.slug">
            <img :src="item.logo" alt="">{{ item.name }}
          </a>
        </template>
        <template v-if="result_list.label && result_list.label.length>0">
          <p>Name tag</p>
          <template v-if="result_list.ens.length > 0">
            <a v-for="item in result_list.label.slice(0, 5)" v-bind:key="item.name_tag" v-on:click="searchFunc(item.name_tag, item.address)">{{ item.name_tag }} <span>{{ item.address }}</span></a>
          </template>
          <template v-else>
            <a v-for="item in result_list.label" v-bind:key="item.name_tag" v-on:click="searchFunc(item.name_tag, item.address)">{{ item.name_tag }} <span>{{ item.address }}</span></a>
          </template>
        </template>
        <template v-if="result_list.ens && result_list.ens.length>0">
          <p>ENS</p>
          <template v-if="result_list.label.length > 0">
            <a v-for="item in result_list.ens.slice(0, 5)" v-bind:key="item.labelNameENS" v-on:click="searchFunc(item.labelNameENS, item.address)">{{ item.labelNameENS }} <span>{{ item.address }}</span></a>
          </template>
          <template v-else>
            <a v-for="item in result_list.ens" v-bind:key="item.labelNameENS" v-on:click="searchFunc(item.labelNameENS, item.address)">{{ item.labelNameENS }} <span>{{ item.address }}</span></a>
          </template>
        </template>
      </div>
    </div>
    <!--    <form id="uploadFile">-->
    <!--      <label for="upload_file"><i class="bi-cloud-upload"></i>Search by file-->
    <!--        <b v-if="from_home_page" class="faqInfo" data-toggle="tooltip" data-placement="top" title="" data-original-title="Select a .txt file that contains less than 100 addresses. Addresses in your file should be separated by a comma or new line. Ex.: 0x123..., 0x234..., 0x345..."></b>-->
    <!--      </label>-->
    <!--      <input type="file" id="upload_file" name="upload_file" accept="text/plain">-->
    <!--    </form>-->
    <div class="kyt-examples" v-if="kyt_page">
      <p>View examples: </p>
      <a target="_blank" href="https://oraclus.com/search/bitcoin/address/1Edue8XZCWNoDBNZgnQkCCivDyr9GEo4x6">BTC</a>
      <a target="_blank" href="https://oraclus.com/search/ethereum/address/0x983a81ca6FB1e441266D2FbcB7D8E530AC2E05A2">ETH</a>
      <a target="_blank" href="https://oraclus.com/search/polygon/address/0xb6f5ec1a0a9cd1526536d3f0426c429529471f40">MATIC</a>
      <a target="_blank" href="https://oraclus.com/search/bnb/address/0x4f47bc496083c727c5fbe3ce9cdf2b0f6496270c">BNB</a>
      <a target="_blank" href="https://oraclus.com/search/arbitrum-one/address/0x4f47bc496083c727c5fbe3ce9cdf2b0f6496270c">ARB</a>
    </div>
  </div>
  <div class="searchDialogBlock blackMode">
    <el-dialog v-model="searchDialog">
      <div class="loading" v-loading="true" v-if="!result_exist"></div>
      <template v-if="result_exist">
        <p class="title">Results for "{{ this.form.search_input }}"</p>
        <div class="searchChainsList">
          <div class="chainBlock" v-for="item in chains" v-bind:key="item.address">
            <a :href="'/search/'+item.slug+'/address/'+this.form.search_input"></a>
            <img v-if="item.logo" :src="item.logo" alt="">
            <div class="content">
              <p class="name">{{ item.name }}</p>
              <p class="address">/search/{{ item.slug }}/{{ this.form.search_input }}</p>
            </div>
            <i class="bi bi-chevron-right"></i>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {SEARCH_ENS_LABEL_CLOUD, CHECK_BLOCKCHAINS} from "@/store/modules/SearchModule";

export default {
  props:{
    for_top_mobile: {
      type: Boolean,
      default: false
    },
    from_home_page: {
      type: Boolean,
      default: false
    },
    kyt_page: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    loading: false,
    form: {
      search_input: '',
    },
    rules:{
      search_input:{
        required:true,
        message: 'Please enter an address'
      },
    },
    show_result:false,
    result_list:[],
    chains_result_list:[],
    searchDialog: false,
    result_exist: false,
    chains:[],
    active_chains_leap: [
      {
        name: 'Bitcoin Cash',
        slug: 'bitcoin-cash',
        logo: "https://static.oraclus.com/static/chains/bitcoin_cach_chain.svg"
      },
      {
        name: 'Ethereum',
        slug: 'ethereum',
        logo: "https://static.oraclus.com/static/chains/ethereum_chain.svg"
      },
      {
        name: 'Bitcoin',
        slug: 'bitcoin',
        logo: "https://static.oraclus.com/static/chains/bitcoin_chain.svg"
      },
      {
        name: 'Dash',
        slug: 'dash',
        logo: "https://static.oraclus.com/static/chains/dash_chain.svg"
      },
      {
        name: 'Dogecoin',
        slug: 'dogecoin',
        logo: "https://static.oraclus.com/static/chains/dogecoin_chain.svg"
      },
      {
        name: 'Litecoin',
        slug: 'litecoin',
        logo: "https://static.oraclus.com/static/chains/litecoin_chain.svg"
      },
      {
        name: 'Zcash',
        slug: 'zcash',
        logo: "https://static.oraclus.com/static/chains/zcash_chain.svg"
      }
    ]
  }),
  methods:{
    async saveLog(search_value){
      let text = search_value;
      try {
        // eslint-disable-next-line no-undef
        saveSearchInputValue(text)
            .then(responseData => {
              console.log(responseData);
            })
            .catch(error => {
              console.error(error);
            });
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async onSearch(){
      if(this.form.search_input.length > 0) {
        this.chains = [];
        this.result_exist = false;
        this.saveLog(this.form.search_input);
        try {
          this.searchDialog = true;
          let response = await this.$store.dispatch(CHECK_BLOCKCHAINS, this.form.search_input);
          if(response && response.length > 0){
            this.chains = response;
            this.result_exist = true;
          }else{
            if(this.chains_result_list.length>0){
              window.location.href = '/chain/' + this.chains_result_list[0].slug
            }else if(this.result_list?.label?.length>0){
              window.location.href = '/search/address/' + this.result_list.label[0].address;
            }else if(this.result_list?.ens?.length>0){
              window.location.href = '/search/address/' + this.result_list.ens[0].address;
            }else{
              if(this.form.search_input.substring(0, 2).toLowerCase() === '0x' && this.form.search_input.length>42){
                window.location.href = '/transaction/ethereum/' + this.form.search_input
              }else if(this.form.search_input.length>58){
                window.location.href = '/transaction/bitcoin/' + this.form.search_input
              } else{
                window.location.href = '/search/address/' + this.form.search_input
              }
            }
          }
        } catch (e) {
          console.log(e);
          if(this.chains_result_list.length>0){
            window.location.href = '/chain/' + this.chains_result_list[0].slug
          }else if(this.result_list?.label?.length>0){
            window.location.href = '/search/address/' + this.result_list.label[0].address;
          }else if(this.result_list?.ens?.length>0){
            window.location.href = '/search/address/' + this.result_list.ens[0].address;
          }else {
            if(this.form.search_input.substring(0, 2).toLowerCase() === '0x' && this.form.search_input.length>42){
              window.location.href = '/transaction/ethereum/' + this.form.search_input
            }else{
              window.location.href = '/search/address/' + this.form.search_input
            }
          }
        }
      }
    },
    async handleInput(){
      if(this.form.search_input !== ''){
        this.loading = true;
        try {
          let response = await this.$store.dispatch(SEARCH_ENS_LABEL_CLOUD, this.form.search_input);
          if(response.ens && response.ens.length > 0){
            this.result_list = response;
            this.show_result = true;
          }else if(response.label && response.label.length > 0){
            this.result_list = response;
            this.show_result = true;
          }else{
            this.result_list = [];
            this.show_result = false;
          }
          this.loading = false;
        } catch (e) {
          this.result_list = [];
          this.show_result = false;
        }
        this.chains_result_list = this.active_chains_leap.filter(obj => obj['slug'].includes(this.form.search_input.toLowerCase()));
        if(this.chains_result_list.length>0){
          this.show_result = true;
        }
      }else{
        this.result_list = [];
        this.show_result = false;
      }
    },
    searchFunc(name, address){
      let text = 'user entered:'+this.form.search_input+' | '+name+' | '+address;
      this.saveLog(text);
      window.location.href = '/search/address/'+address
    }
  },
  created() {
  }
}
</script>
