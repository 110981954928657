<template>
  <el-container id="headerContainer">
    <el-row :gutter="30" align="middle" class="headerMenuRow">
      <el-col :sm="16">
        <el-row :gutter="30" align="middle">
          <el-col :span="6" :class="header_off?'show_header_true':'show_header_false'">
            <a class="logo" href="/"><img src="@/assets/img/logo.svg" alt=""></a>
          </el-col>
          <el-col :sm="18" v-if="header_search || showHeaderSearch">
            <HeaderSearchComponent></HeaderSearchComponent>
          </el-col>
        </el-row>
      </el-col>
      <el-col :sm="8" class="text-right">
        <div class="menu" :class="mobileBurgerToggle?'opened':''">
<!--          <a class="linkButton telegram" href="/pricing">Pricing</a>-->
          <a class="linkButton telegram hideForMobile" href="/cabinet/custom-reports">Portfolio</a>
          <a class="linkButton listButton"><i class="bi-three-dots"></i>
            <ul class="dropdownList">
              <li><a href="/api/" onclick="sendMetric('apidocs');" target="_blank">API docs</a></li>
              <li><a href="https://blog.oraclus.com/en" onclick="sendMetric('clickblog');">Blog</a></li>
            </ul>
          </a>
          <template v-if="!userInfo">
            <a class="linkButton loginButtonHeader" href="/login">Log in</a>
            <a class="linkButton registerButtonHeader" href="/register">Sign up</a>
          </template>
          <hr/>
          <template v-if="userInfo">
            <a class="linkButton linkForMobile" href="/cabinet/custom-reports">My Portfolio tracker</a>
            <a class="linkButton linkForMobile" href="/cabinet/alerts">My Alerts</a>
            <a class="linkButton linkForMobile" href="/cabinet/watchlist">My watchlist</a>
              <a class="linkButton linkForMobile" href="/cabinet/account">Account settings</a>
              <a class="linkButton linkForMobile" href="/cabinet/plans">Subscription</a>
              <LogoutComponent></LogoutComponent>
          </template>
          <template v-else>
              <a class="linkButton linkForMobile" href="/login">Login</a>
              <a class="linkButton linkForMobile" href="/register">Sign Up</a>
          </template>
        </div>
        <ActivePlanComponent v-if="userInfo" class="forMobile" />
        <el-button @click="this.mobileBurgerToggle=!this.mobileBurgerToggle" class="mobileBurgerMenu"><i class="bi-list"></i></el-button>
        <HeaderProfileComponent v-if="userInfo"></HeaderProfileComponent>
<!--        <LogoutComponent></LogoutComponent>-->
      </el-col>
    </el-row>
  </el-container>
</template>
<script>
import HeaderSearchComponent from '@/views/default-components/SearchComponent'
import HeaderProfileComponent from '@/views/cabinet-components/HeaderProfileComponent'
import LogoutComponent from '@/views/cabinet-components/LogoutComponent'
import ActivePlanComponent from "@/views/default-components/header-components/ActivePlanComponent";
export default {
  components:{
    HeaderSearchComponent,
    HeaderProfileComponent,
    LogoutComponent,
    ActivePlanComponent
  },
  data:()=>({
    showHeaderSearch: '',
    mobileBurgerToggle: false
  }),
  props: {
    header_off: {
      type: Boolean,
      default: false
    },
    header_search: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    '$route'() {
      this.showHeaderSearch = this.$route.meta.showHeaderSearchMeta;
    }
  },
  created() {
    this.showHeaderSearch = this.$route.meta.showHeaderSearchMeta;
  },
  computed: {
    userInfo() {
      return this.$store.getters.get_user_info;
    }
  }
}
</script>
