<template>
  <template v-if="userInfo">
    <p class="userScoreText" @click="infoToggle">P{{ JSON.parse(userInfo).score?JSON.parse(userInfo).score:'' }}
      <span v-if="JSON.parse(userInfo).referral_code && mini_text && JSON.parse(userInfo).score">
        <img src="@/assets/img/new/close.svg" class="closeImg" alt="">
        <i>Invite 1 friend and get access to more features <i class="link" onclick="window.location.href='/gamification'">How?</i></i>
        <b @click="copyToClipboard(JSON.parse(userInfo).referral_code)">Your invitation URL <img src="@/assets/img/new/copy.svg" alt=""></b>
      </span>
    </p>
  </template>
  <template v-else>
    <p class="userScoreText" @click="infoToggle">P0
      <span v-if="mini_text">
        <img src="@/assets/img/new/close.svg" class="closeImg" alt="">
        <i>Invite 1 friend and get access to more features <i class="link" onclick="window.location.href='/gamification'">How?</i></i>
        <b @click="openUrl('/register')">Sign up</b>
      </span>
    </p>
  </template>
</template>
<script>
export default {
  data: () => ({
    mini_text: false
  }),
  methods:{
    copyToClipboard(referral_code) {
      navigator.clipboard.writeText('https://oraclus.com/register?invitation='+referral_code).then(
          () => {
            this.$message.success('URL copied to clipboard');
          },
          (err) => {
            console.error('Could not copy text: ', err)
          }
      )
    },
    openUrl(url_text){
      window.location.href=url_text;
    },
    infoToggle(){
      this.mini_text = !this.mini_text
      localStorage.setItem('score_visible', 'hide');
    }
  },
  created() {
    if(this.scoreVisible){
      // console.log(this.scoreVisible);
      this.mini_text = false;
    }else{
      // console.log('null');
      this.mini_text = true;
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.get_user_details;
    },
    scoreVisible() {
      return this.$store.getters.get_score_visible;
    }
  }
}
</script>
