import {DefaultAPIInstance} from "@/plugins/axios";
export const GET_VC_LIST = "GET_VC_LIST";

const state = {
  my_watchlist:[]
}

const actions = {
  [GET_VC_LIST]: async (store, current_page) => {
    const {data} = await DefaultAPIInstance({url: '/sv1/watch_vc/list/?page='+current_page, method: 'GET'});
    return data;
  },
}

const mutations = {
}

const getters = {
}
export default {
  state,
  actions,
  mutations,
  getters
}
