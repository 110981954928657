import { createStore } from 'vuex'

// Modules
import AuthModule from './modules/AuthModule'
import WatchlistModule from './modules/WatchlistModule'
import SearchModule from './modules/SearchModule'
import TopAddressesModule from './modules/TopAddressesModule'
import AudienceAnalyticsModule from './modules/AudienceAnalyticsModule'
import IndividualMetricsModule from './modules/IndividualMetricsModule'
import HomePageModule from './modules/HomePageModule'
import DashboardNetflowModule from './modules/DashboardNetflowModule'
import AlertModule from './modules/AlertModule'
import PlansModule from './modules/PlansModule'
import CustomReportsModule from './modules/CustomReportsModule'
import TransactionsModule from './modules/TransactionsModule'
import ChainModule from './modules/ChainModule'
import VcTrackerModule from './modules/VcTrackerModule'
import SeiModule from './modules/SeiModule'
import ChatModule from './modules/ChatModule'

export default createStore({
  modules: {
      AuthModule,
      WatchlistModule,
      SearchModule,
      TopAddressesModule,
      AudienceAnalyticsModule,
      IndividualMetricsModule,
      HomePageModule,
      DashboardNetflowModule,
      AlertModule,
      PlansModule,
      CustomReportsModule,
      TransactionsModule,
      ChainModule,
      VcTrackerModule,
      SeiModule,
      ChatModule
  }
})
