<template>
  <div class="newOraclus apiPages">
    <el-container class="main-container">
      <HeaderComponent :header_off="true" :header_search="true"></HeaderComponent>
      <el-main id="mainBlock">
        <el-container class="apiContainer">
          <el-row :gutter="30">
            <el-col :span="24" v-if="this.is_mobile">
              <button class="apiMenuButton" @click="this.show_menu=!this.show_menu">API navigation <img src="@/assets/img/landing/burger.svg" alt=""></button>
            </el-col>
            <el-col :xs={span:24} :sm={span:5} class="menuForMobile" :class="this.show_menu?'showMenu':''">
              <p class="menuHead">Overview</p>
              <ul class="navMenu">
                <li><router-link :class="this.$route.meta.activeNav===1?'active':''" @click="this.show_menu=false" :to="{name:'ApiIntroductionPage'}">Introduction</router-link></li>
<!--                <button class="openChild" :class="childNavOpened?'opened':''" @click="childNavOpened=!childNavOpened">Use Cases</button>-->
<!--                <ul class="navMenu childNav" v-if="childNavOpened">-->
<!--                  <li><router-link @click="this.show_menu=false" :to="{name:'ApiIntroductionPage'}">Wallet Analytics APIs</router-link></li>-->
<!--                  <li><router-link @click="this.show_menu=false" :to="{name:'ApiIntroductionPage'}">Flow APIs</router-link></li>-->
<!--                </ul>-->
              </ul>
              <p class="menuHead">Quick Start</p>
              <ul class="navMenu">
                <li><router-link :class="this.$route.meta.activeNav===2?'active':''" @click="this.show_menu=false" :to="{name:'ApiQuickStartWalletAnalytics'}">Wallet Analytics APIs</router-link></li>
                <li><router-link :class="this.$route.meta.activeNav===3?'active':''" @click="this.show_menu=false" :to="{name:'ApiQuickStartFlow'}">Flow APIs</router-link></li>
                <li><router-link :class="this.$route.meta.activeNav===6?'active':''" @click="this.show_menu=false" :to="{name:'ApiQuickStartGpt'}">Oraclus GPT APIs</router-link></li>
              </ul>
              <p class="menuHead">Tutorials</p>
              <ul class="navMenu">
                <li><router-link :class="this.$route.meta.activeNav===4?'active':''" @click="this.show_menu=false" :to="{name:'GetTransaction'}">How to get transaction information in a given chain</router-link></li>
                <li><router-link :class="this.$route.meta.activeNav===5?'active':''" @click="this.show_menu=false" :to="{name:'GetEthereum'}">How to get Ethereum, ERC-20, ERC-721, ERC-1155, transactions in Ethereum blockchain</router-link></li>
              </ul>
              <p class="menuHead">API Docs</p>
              <ul class="navMenu">
                <li><a @click="this.show_menu=false" href="/api-integration">Wallet Analytics APIs</a></li>
                <li><a @click="this.show_menu=false" href="/api-integration?activeTab=2">Flow APIs</a></li>
                <li><a @click="this.show_menu=false" href="/api-integration?activeTab=3">Oraclus GPT APIs</a></li>
              </ul>
            </el-col>
            <el-col :xs={span:24} :sm={span:19} class="apiBody">
              <router-view></router-view>
            </el-col>
          </el-row>
        </el-container>
      </el-main>
      <el-footer>
        <FooterComponent></FooterComponent>
      </el-footer>
    </el-container>
    <DiscountComponent :fixed="true" />
  </div>

</template>

<script>
import HeaderComponent from '@/views/default-components/HeaderComponent'
import FooterComponent from '@/views/default-components/FooterComponent'
import DiscountComponent from "@/views/default-components/DiscountComponent";
export default {
  name: 'ApiLayout',
  components:{
    HeaderComponent,
    FooterComponent,
    DiscountComponent
  },
  data:()=>({
    childNavOpened: false,
    is_mobile: false,
    show_menu: false
  }),
  created() {
    if(screen.width <= 678){
      this.is_mobile = true;
    }
  }
};
</script>
