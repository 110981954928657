import {ChainsAPIInstance} from "@/plugins/axios";
export const GET_CHAINS = "GET_CHAINS";
export const GET_CHAIN_INFO = "GET_CHAIN_INFO";

const state = {

}

const actions = {
  [GET_CHAINS]:async ()=>{
    const { data }  = await ChainsAPIInstance({url:'/chains',method:'GET'});
    return data;
  },
  [GET_CHAIN_INFO]:async (store, chain_value)=>{
    const { data }  = await ChainsAPIInstance({url:'/stats/'+chain_value,method:'GET'});
    return data;
  },
}

const mutations = {

}

const getters = {

}
export default {
  state,
  actions,
  mutations,
  getters
}
