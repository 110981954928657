import {DefaultAPIInstance} from "@/plugins/axios";
export const GET_ALERTS = "GET_ALERTS";
export const ADD_ALERT_ITEM = "ADD_ALERT_ITEM";
export const UPDATE_ALERT_ITEM = "UPDATE_ALERT_ITEM";
export const UPDATE_MY_ALERTS = "UPDATE_MY_ALERTS";
export const DELETE_ALERT_ITEM = "DELETE_ALERT_ITEM";
export const ENABLE_ALERT_ITEM = "ENABLE_ALERT_ITEM";
export const DISABLE_ALERT_ITEM = "DISABLE_ALERT_ITEM";

const state = {
  my_alerts:[],
  saved_alert: localStorage.getItem('saved_alert') || '',
  options:{
    tokens:[],
    value_type:[
      {
        value: 'send/receive',
        label: 'receives(in)/sends(out)',
      },
      {
        value: 'send',
        label: 'sends(out)',
      },
      {
        value: 'receive',
        label: 'receives(in)',
      }
    ],
    value_condition:[
      {
        value: '>=',
        label: '> more than',
      },
      {
        value: '<=',
        label: '< less than',
      },
      {
        value: '<>',
        label: 'any',
      }
    ],
    activities_condition:[
      {
        value: '>=',
        label: 'above',
      },
      {
        value: '<=',
        label: 'below',
      }
    ],
    frequency_choices:[
      {
        value: 'hour',
        label: 'Hour(s)',
        disabled: false
      },
      {
        value: 'day',
        label: 'Day(s)',
        disabled: true
      },
      {
        value: 'week',
        label: 'Week(s)',
        disabled: true
      }
    ]
  }
}

const actions = {
  [GET_ALERTS]:async (store)=>{
    const { data }  = await DefaultAPIInstance({url:'/alert/',method:'GET'});
    store.commit(UPDATE_MY_ALERTS, data);
    return data;
  },
  [ADD_ALERT_ITEM]:async (store, form)=>{
    const { data }  = await DefaultAPIInstance({url:'/alert/',method:'POST',data:form});
    store.dispatch(GET_ALERTS);
    return data;
  },
  [UPDATE_ALERT_ITEM]:async (store, form)=>{
    const { data }  = await DefaultAPIInstance({url:'/alert/'+form.id+'/',method:'PATCH',data:form});
    store.dispatch(GET_ALERTS);
    return data;
  },
  [ENABLE_ALERT_ITEM]:async (store, alert_id)=>{
    const { data }  = await DefaultAPIInstance({url:'/alert/switcher/'+alert_id+'/',method:'POST'});
    store.dispatch(GET_ALERTS);
    return data;
  },
  [DISABLE_ALERT_ITEM]:async (store, alert_id)=>{
    const { data }  = await DefaultAPIInstance({url:'/alert/switcher/'+alert_id+'/',method:'DELETE'});
    store.dispatch(GET_ALERTS);
    return data;
  },
  [DELETE_ALERT_ITEM]:async (store, alert_id)=>{
    const { data }  = await DefaultAPIInstance({url:'/alert/'+alert_id+'/',method:'DELETE'});
    store.dispatch(GET_ALERTS);
    return data;
  },
}

const mutations = {
  [UPDATE_MY_ALERTS]: (state, data) => {
    state.my_alerts = data;
  },
}

const getters = {
  get_my_alerts: (state) => state.my_alerts,
  get_alert_options: (state) => state.options,
  get_saved_alert: (state) => state.saved_alert,
}
export default {
  state,
  actions,
  mutations,
  getters
}
