<template>
    <el-link class="linkButton linkForMobile logoutButton" type="info" @click="logoutFunc"><i class="bi-box-arrow-in-left"></i> Logout</el-link>
</template>

<script>
import {ON_LOGOUT} from '@/store/modules/AuthModule';

export default {
  name: 'LogoutComponent',
  methods: {
    logoutFunc() {
      this.$store.dispatch(ON_LOGOUT);
      // this.$router.push({ name: 'Login'});
      window.location = '/';
    }
  }
}
</script>
