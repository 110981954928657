<template>
  <div class="common-layout">
    <el-container class="main-container">
      <el-aside>
        <AsideComponent></AsideComponent>
      </el-aside>
      <el-container class="cabinet-container">
        <el-header>
          <HeaderComponent :header_off="true" :header_search="true"></HeaderComponent>
        </el-header>
        <el-main>
          <DiscountCabinetComponent />
          <router-view></router-view>
        </el-main>
        <el-footer v-if="false">
          <FooterComponent class="greyTheme" :inner="true"></FooterComponent>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import AsideComponent from '@/views/cabinet-components/AsideComponent'
import HeaderComponent from '@/views/cabinet-components/HeaderComponent'
import FooterComponent from '@/views/default-components/FooterComponent'
import DiscountCabinetComponent from '@/views/cabinet-components/DiscountCabinetComponent'

export default {
  name: 'CabinetLayout',
  components:{
    AsideComponent,
    HeaderComponent,
    FooterComponent,
    DiscountCabinetComponent
  }
};
</script>
