import {SecondAPIInstance} from "@/plugins/axios";
export const GET_TOP_ADDRESSES = "GET_TOP_ADDRESSES";

const state = {

}

const actions = {
  [GET_TOP_ADDRESSES]:async ()=>{
    const { data }  = await SecondAPIInstance({url:'/accounts/top/',method:'GET'});
    return data;
  },
}

const mutations = {

}

const getters = {

}
export default {
  state,
  actions,
  mutations,
  getters
}
