import {LoginAPIInstance} from "@/plugins/axios";
export const GET_INDIVIDUAL_METRICS_ERC20 = "GET_INDIVIDUAL_METRICS_ERC20";

const state = {

}

const actions = {
  [GET_INDIVIDUAL_METRICS_ERC20]:async ()=>{
    const { data }  = await LoginAPIInstance({url:'individual_metrics/ethereum/erc20/erc20_txns_details/0x3ddfa8ec3052539b6c9549f12cea2c295cff5296',method:'GET'});
    return data;
  },
}

const mutations = {

}

const getters = {

}
export default {
  state,
  actions,
  mutations,
  getters
}
