<template>
  <a href="/chat" class="discountLink" :class="fixed?'fixedStyle':''">
    <img src="@/assets/img/new/rocket.png" alt=""><span>Ask Oraclus GPT (Beta)</span><img src="@/assets/img/white-arrow.svg" alt="">
  </a>
</template>
<script>
export default {
  props:{
    fixed: Boolean
  }
}
</script>
