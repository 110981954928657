import {ChatAPIInstance, DefaultAPIInstance} from "@/plugins/axios";
export const SET_QUESTION = "SET_QUESTION";
export const GET_SHARE_SESSION_ID = "GET_SHARE_SESSION_ID";
export const READ_SHARED_SESSION_ID = "READ_SHARED_SESSION_ID";
export const SEND_FEEDBACK = "SEND_FEEDBACK";
export const CREATE_API_KEY = "CREATE_API_KEY";
export const GET_API_KEYS = "GET_API_KEYS";
export const GET_TOKENS = "GET_TOKENS";

const state = {
  local_sessions: localStorage.getItem('chat_sessions') || []
}

const actions = {
  [SET_QUESTION]:async (store, form)=>{
    const { data }  = await ChatAPIInstance({url:'/ai',method:'POST', data:form});
    return data;
  },
  [GET_SHARE_SESSION_ID]:async (store, session_id)=>{
    const { data }  = await ChatAPIInstance({url:'/share/'+session_id,method:'GET'});
    return data;
  },
  [READ_SHARED_SESSION_ID]:async (store, shared_session_id)=>{
    const { data }  = await ChatAPIInstance({url:'/read/'+shared_session_id,method:'GET'});
    return data;
  },
  [SEND_FEEDBACK]:async (store, form)=>{
    const { data }  = await ChatAPIInstance({url:'/ai/'+form.session_id+'/feedback',method:'POST', data:form});
    return data;
  },
  [CREATE_API_KEY]:async ()=>{
    const { data }  = await DefaultAPIInstance({url:'/api_keys/',method:'POST'});
    return data;
  },
  [GET_API_KEYS]:async ()=>{
    const { data }  = await DefaultAPIInstance({url:'/api_keys/',method:'GET'});
    return data;
  },
  [GET_TOKENS]:async ()=>{
    const { data }  = await DefaultAPIInstance({url:'/subscriptions/tokens/',method:'GET'});
    return data;
  },
}

const mutations = {}

const getters = {
  get_local_sessions: (state) => state.local_sessions
}
export default {
  state,
  actions,
  mutations,
  getters
}
