<template>
  <div>
    <el-container>
      <el-row :gutter="30">
        <el-col :xs="{span: 24}" :sm="{span: 0}"><div class="line"></div></el-col>
        <el-col :xs="{span: 24}" :sm="{span: 0}">
          <a href="/" class="logo">
            <img v-if="this.inner" src="@/assets/img/landing/logo2.png" alt="">
            <img v-else src="@/assets/img/landing/logo.png" alt="">
          </a>
        </el-col>
        <el-col :xs="{span: 24}" :sm="{span: 0}"><div class="line"></div></el-col>
        <el-col :sm="{span: 7}">
          <ul>
            <li><p>Products</p></li>
            <li><a href="/cabinet/alerts">Custom Alerts</a></li>
            <li><a href="/home">Inflow and Outflow Explorer</a></li>
            <li><a href="/api/" target="_blank">API Integration</a></li>
          </ul>
        </el-col>
        <el-col :sm="{span: 5}">
          <ul>
            <li><p>Company Info</p></li>
            <!--                <li><a>Pricing</a></li>-->
            <li><a href="https://blog.oraclus.com/" target="_blank">Blog</a></li>
          </ul>
        </el-col>
        <el-col :sm="{span: 4}">
          <ul>
            <li><p>Contact Us</p></li>
            <li><FeedbackDialogComponent /></li>
          </ul>
        </el-col>
        <el-col :sm="{span: 8}">
          <GetUpdatesComponent />
        </el-col>
        <el-col :span="24"><div class="line"></div></el-col>
      </el-row>
    </el-container>
    <el-container class="secondContainer">
      <el-row :gutter="30" align="middle">
        <el-col :xs="{span: 0}" :sm="{span: 8}">
          <a href="/" class="logo">
            <img v-if="this.inner" src="@/assets/img/landing/logo2.png" alt="">
            <img v-else src="@/assets/img/landing/logo.png" alt="">
          </a>
        </el-col>
        <el-col :xs="{span: 0}" :sm="{span: 8}">
          <ul class="menu">
            <li><a href="/privacy-policy">Privacy Policy</a></li>
          </ul>
        </el-col>
        <el-col :sm="{span: 8}" class="text-right">
          <ul class="socialLinks">
            <li><a href="https://www.linkedin.com/company/oraclus/" target="_blank"><span><img src="@/assets/img/landing/linkedin.svg" alt=""></span></a></li>
            <li><a href="https://t.me/oraclus_reports?utm_source=ooraclus&utm_medium=jjump&utm_campaign=ffixed_btn" target="_blank"><span><img src="@/assets/img/landing/telegram2.svg" alt=""></span></a></li>
            <li><a href="https://twitter.com/oraclus_com" target="_blank"><span><img src="@/assets/img/landing/twitter.svg" alt=""></span></a></li>
          </ul>
        </el-col>
        <el-col :xs="{span: 24}" :sm="{span: 0}">
          <ul class="menu">
            <li><a href="/privacy-policy">Privacy Policy</a></li>
          </ul>
        </el-col>
        <el-col :span="24" v-if="inner">
          <p class="usingText">
            <span>We employ the following services to compute our information:</span>
            <!--              <img src="/search-assets/info1.1.png" alt=""><span>CoinMarketCap</span>-->
            <a href="https://www.coingecko.com/" target="_blank" class="greyLink"><img src="/search-assets/info2.png" alt=""><span>CoinGecko</span></a>
            <a href="https://blockchair.com/" target="_blank" class="greyLink"><img src="/search-assets/info3.png" alt=""><span>Blockchair</span></a>
          </p>
        </el-col>
        <el-col :span="24">
          <p class="disclaimerText">
            Important Disclaimer: All the information presented on our website, including content found on hyperlinked sites, associated applications, forums, blogs, social media accounts, and other platforms, is provided solely for general informational purposes. This information is obtained from third-party sources, and we do not offer any guarantees or warranties regarding its accuracy or currentness. None of the content we provide should be construed as financial advice, legal advice, or any other type of advice intended for your specific reliance for any purpose. You are solely responsible for any utilization or trust placed in our content, and it is important that you independently undertake research, assessment, scrutiny, and validation of our content before arriving at any decisions. None of the information provided on our website should be interpreted as an invitation or proposal. Engaging in trading carries inherent risks that may lead to substantial financial losses, and as such, we highly advise seeking guidance from a qualified financial advisor before making any decisions.
          </p>
        </el-col>
      </el-row>
    </el-container>
  </div>
</template>

<script>
import FeedbackDialogComponent from "@/views/default-components/footer-components/FeedbackDialogComponent";
import GetUpdatesComponent from "@/views/default-components/footer-components/GetUpdatesComponent";

export default {
  props:{
    inner: Boolean,
  },
  components:{
    FeedbackDialogComponent,
    GetUpdatesComponent
  },
}
</script>
