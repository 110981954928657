<template>
  <div class="newsletterBlock">
    <p>{{ this.success_show?'Thank you':'Get Updates' }}</p>
    <el-input class="defaultInputBlock" v-model="this.register_email" placeholder="Email address" v-if="!success_show">
      <template #append>
        <el-button type="primary" :disabled="this.register_email===''" @click="onSubmit"><img src="@/assets/img/landing/arrowWhite.svg" alt=""></el-button>
      </template>
    </el-input>
    <template v-if="this.success_show">
      <p class="descr">
        Your subscription has been confirmed. You've been added to our list and will hear from us soon.
      </p>
    </template>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialogVisible: false,
    register_email: '',
    success_show: false
  }),
  methods:{
    async onSubmit(){
      let text = 'Get Updates Form | Email:' + this.register_email;
      try {
        // eslint-disable-next-line no-undef
        sendFeedbackForVue(text)
            .then(responseData => {
              console.log(responseData);
              this.success_show = true;
            })
            .catch(error => {
              console.error(error);
              this.success_show = true;
            });
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }
}
</script>
