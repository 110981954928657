import {DefaultAPIInstance} from "@/plugins/axios";
import {GET_USER_INFO} from "@/store/modules/AuthModule";
export const GET_WATCHLIST = "GET_WATCHLIST";
export const ADD_WATCHLIST_ITEM = "ADD_WATCHLIST_ITEM";
export const UPDATE_MY_WATCHLIST = "UPDATE_MY_WATCHLIST";
export const DELETE_WATCHLIST_ITEM = "DELETE_WATCHLIST_ITEM";

const state = {
    my_watchlist:[]
}

const actions = {
    [GET_WATCHLIST]:async (store)=>{
        const { data }  = await DefaultAPIInstance({url:'/watchlist/',method:'GET'});
        store.commit(UPDATE_MY_WATCHLIST, data);
        return data;
    },
    [ADD_WATCHLIST_ITEM]:async (store, form)=>{
        const { data }  = await DefaultAPIInstance({url:'/watchlist/',method:'POST',data:form});
        store.dispatch(GET_USER_INFO);
        return data;
    },
    [DELETE_WATCHLIST_ITEM]:async (store, watchlist_id)=>{
        const { data }  = await DefaultAPIInstance({url:'/watchlist/'+watchlist_id+'/',method:'DELETE'});
        store.dispatch(GET_USER_INFO);
        return data;
    },
}

const mutations = {
    [UPDATE_MY_WATCHLIST]: (state, data) => {
        state.my_watchlist = data;
    },
}

const getters = {
    get_my_watchlist: (state) => state.my_watchlist,
}
export default {
    state,
    actions,
    mutations,
    getters
}
