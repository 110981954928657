import {NetflowAPIInstance, SecondAPIInstance} from "@/plugins/axios";
export const GET_NETFLOW_OPTIONS = "GET_NETFLOW_OPTIONS";
export const GET_TOKENS = "GET_TOKENS";
export const GET_REPORT_FOR_HOME = "GET_REPORT_FOR_HOME";
export const GET_COINS = "GET_COINS";
export const GET_PRICE_OF_COIN = "GET_PRICE_OF_COIN";
export const GET_FLOW_FOR_HOME = "GET_FLOW_FOR_HOME";
export const GET_ALERT_EVENTS = "GET_ALERT_EVENTS";

const state = {

}

const actions = {
  [GET_NETFLOW_OPTIONS]:async ()=>{
    const { data }  = await NetflowAPIInstance({url:'/api/netflow/options/',method:'GET'});
    return data;
  },
  [GET_TOKENS]:async (store, search_value)=>{
    if(search_value){
      const { data }  = await SecondAPIInstance({url:'/v1/assets/alert_assets/?page_size=20&search='+search_value,method:'GET'});
      return data;
    }else{
      const { data }  = await SecondAPIInstance({url:'/v1/assets/alert_assets/?page_size=20',method:'GET'});
      return data;
    }
  },
  [GET_REPORT_FOR_HOME]:async (store, array_data)=>{
    let form_param = '';
    if(array_data[0].project !== ''){
      form_param = '/'+array_data[0].project+'/';
    }
    if(array_data[0].top !== ''){
      form_param = '/'+array_data[0].top+'/';
    }
    if(array_data[0].project_category !== ''){
      form_param = '';
    }
    const { data }  = await SecondAPIInstance({url:'/v1/netflow/'+array_data[0].chain+'/'+array_data[0].asset+form_param+'?period='+array_data[1],method:'GET'});
    return data;
  },
  [GET_COINS]:async (store, search_value)=>{
    if(search_value){
      const { data }  = await SecondAPIInstance({url:'/api/sv1/assets/?search='+search_value,method:'GET'});
      return data;
    }else{
      const { data }  = await SecondAPIInstance({url:'/api/sv1/assets/',method:'GET'});
      return data;
    }
  },
  [GET_PRICE_OF_COIN]:async (store, array_data)=>{
    const { data }  = await SecondAPIInstance({url:'/api/sv1/assets/'+array_data[0]+'/?period='+array_data[1],method:'GET'});
    return data;
  },
  [GET_FLOW_FOR_HOME]:async (store, filter)=>{
    const { data }  = await NetflowAPIInstance({url:'/api/netflow/report/'+filter.chain+'/',method:'GET'});
    return data;
  },
  [GET_ALERT_EVENTS]:async (store, form)=>{
    const { data }  = await SecondAPIInstance({url:'https://api.oraclus.com/api/alert/events/?alert_id='+form.alert_id+'&from='+form.date_from+'&to='+form.date_to,method:'GET'});
    return data;
  },
}

const mutations = {

}

const getters = {

}
export default {
  state,
  actions,
  mutations,
  getters
}
