<template>
  <div class="headerButtonBlock">
    <UserScoreComponent/>
    <template v-if="userInfo">
      <el-button class="defaultButton mini circle mrClass forOpenMenu" @click="showMenu = !showMenu" :class="showMenu?'opened':''">
        <ActivePlanComponent/>
        <img src="@/assets/img/new/profile.svg" alt="" class="forOpenMenu">
        <img src="@/assets/img/new/arrow.svg" alt="" class="arrow forOpenMenu">
      </el-button>
      <ul class="dropdownMenu" v-if="showMenu">
        <li>
          <a href="https://t.me/oraclus_reports?utm_source=ooraclus&utm_medium=jjump&utm_campaign=ffixed_btn" target="_blank" class="telegram">
            <img src="@/assets/img/landing/telegram.svg" alt="">
            <span>Free Daily Reports</span>
          </a>
        </li>
<!--        <li><a class="defaultLink" href="/pricing"><span>Pricing</span></a></li>-->
        <li><a class="defaultLink" href="/api/"><span>API docs</span></a></li>
        <li><a href="/cabinet/custom-reports"><img src="@/assets/img/new/menu1.svg" alt=""><span>My portfolio tracker</span></a></li>
        <li><a href="/cabinet/alerts"><img src="@/assets/img/new/menu3.svg" alt=""><span>My alerts</span></a></li>
        <li><a href="/cabinet/watchlist"><img src="@/assets/img/new/menu2.svg" alt=""><span>My watchlist</span></a></li>
        <li><a href="/cabinet/account"><img src="@/assets/img/new/menu4.svg" alt=""><span>Account Settings</span></a></li>
        <li><a @click="logoutFunc"><img src="@/assets/img/new/menu5.svg" alt=""><span>Logout</span></a></li>
      </ul>
    </template>
  </div>
</template>
<script>
import {ON_LOGOUT} from "@/store/modules/AuthModule";
import ActivePlanComponent from "@/views/default-components/header-components/ActivePlanComponent";
import UserScoreComponent from "@/views/default-components/header-components/UserScoreComponent";

export default {
  components:{
    ActivePlanComponent,
    UserScoreComponent
  },
  data: () => ({
    showMenu: false,
    is_mobile: false,
  }),
  created() {
    let that = this;
    document.addEventListener('click', function (el) {
      if(!el.target.className.includes('forOpenMenu')) {
        that.showMenu = false;
      }
    });
    if(screen.width <= 678){
      this.is_mobile = true;
    }
  },
  methods:{
    logoutFunc() {
      this.$store.dispatch(ON_LOGOUT);
      // this.$router.push({ name: 'Login'});
      window.location = '/';
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.get_user_details;
    }
  }
}
</script>
