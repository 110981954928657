<template>
  <template v-if="userInfo">
    <a href="#" class="activePlanLink">{{ JSON.parse(userInfo).user_plan?JSON.parse(userInfo).user_plan.selected_plan.user_view_name:'Basic' }}</a>
  </template>
</template>
<script>
export default {
  computed: {
    userInfo() {
      return this.$store.getters.get_user_details;
    }
  }
}
</script>
