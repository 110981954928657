<template>
  <el-header>
    <el-container>
      <el-row :gutter="30" align="middle">
        <el-col :xs="{span:10}" :sm="{span:6}" :md="{span: 4}" class="zIndex2" :class="this.show_search?'opacityNull':''">
          <a href="/" class="logo"><img src="@/assets/img/landing/logo.png" alt=""></a>
        </el-col>
        <el-col :xs="{span: 0}" :sm="{span: 7}" :md="{span: 7}" v-if="!this.is_mobile">
          <SearchComponent />
        </el-col>
        <template v-if="this.is_mobile && this.show_search">
          <SearchComponent :for_top_mobile="'true'" />
          <button class="closeSearchBarButton" @click="this.show_search=false"><img src="@/assets/img/landing/close.svg" alt=""></button>
        </template>
        <ul class="forMobile menu" :class="this.burger_bool?'menu-opened':''">
          <li v-if="!is_mobile">
            <a href="https://t.me/oraclus_reports?utm_source=ooraclus&utm_medium=jjump&utm_campaign=ffixed_btn" target="_blank" class="telegram">
              <img src="@/assets/img/landing/telegram.svg" alt="">
              <span>Free Daily Reports</span>
            </a>
          </li>
<!--          <li>-->
<!--            <a class="defaultLink" href="/pricing">Pricing</a>-->
<!--          </li>-->
          <li>
            <a class="defaultLink" href="/cabinet/custom-reports"><img src="@/assets/img/new/menu1.svg" alt=""><span>My portfolio tracker</span></a>
          </li>
          <li>
            <a class="defaultLink" href="/api/">API docs</a>
          </li>
        </ul>
        <div class="forMobile reportsAndLoginButtons" v-if="this.burger_bool">
          <a href="https://t.me/oraclus_reports?utm_source=ooraclus&utm_medium=jjump&utm_campaign=ffixed_btn" target="_blank" class="telegram">
            <img src="@/assets/img/landing/telegram.svg" alt="">
            <span>Free Daily Reports</span>
          </a>
          <template v-if="!userInfo">
            <a href="/login" class="defaultButton mini">
              <span>Login</span>
            </a>
          </template>
          <template v-if="userInfo">
            <a href="/cabinet/alerts" class="defaultButton mini">
              <ActivePlanComponent/>
              <span>Cabinet</span>
            </a>
          </template>
        </div>
        <el-col :xs="{span:14}" :sm="{span:11}" :md="{span:13}" class="text-right zIndex2" :class="this.show_search?'opacityNull':''">
          <a class="defaultLink" onclick="sendMetric('vc_link')" href="/vc-tracker">VC tracker</a>
          <a class="defaultLink" onclick="sendMetric('whales_link')" href="/dashboard/?chain=bitcoin&asset=bitcoin&top=top10">Whales</a>
          <a class="defaultLink" onclick="sendMetric('aml_risk')" href="https://kyt.oraclus.com/">AML risk</a>
          <button class="openSearchButton" v-if="this.is_mobile" @click="this.show_search=true"><i class="bi-search"></i></button>
          <AuthUserMenu/>
          <template v-if="!userInfo">
            <a href="/login" class="defaultButton mini">
              <span>Login</span>
            </a>
          </template>
          <button class="burger" v-if="!userInfo" @click="this.burger_bool = !this.burger_bool">
            <img src="@/assets/img/landing/burger.svg" alt="" v-if="!this.burger_bool">
            <img src="@/assets/img/landing/close.svg" alt="" v-if="this.burger_bool">
          </button>
        </el-col>
        <el-col :span="24" v-if="this.is_mobile && !this.burger_bool">
          <div class="topLinks">
            <a onclick="sendMetric('vc_link')" href="/vc-tracker">VC tracker</a>
            <a onclick="sendMetric('whales_link')" href="/dashboard/?chain=bitcoin&asset=bitcoin&top=top10">Whales</a>
            <a onclick="sendMetric('aml_risk')" href="https://kyt.oraclus.com/">AML risk</a>
          </div>
        </el-col>
      </el-row>
    </el-container>
    <FeedbackDialogComponent :fixed="true" />
  </el-header>
</template>

<script>
import ActivePlanComponent from "@/views/default-components/header-components/ActivePlanComponent";
import SearchComponent from '@/views/default-components/SearchComponent'
import AuthUserMenu from '@/views/default-components/header-components/AuthUserMenu'
import FeedbackDialogComponent from '@/views/default-components/footer-components/FeedbackDialogComponent'
export default {
  components:{
    ActivePlanComponent,
    SearchComponent,
    AuthUserMenu,
    FeedbackDialogComponent
  },
  data: () => ({
    burger_bool: false,
    is_mobile: false,
    show_search: false
  }),
  methods:{
    scrollToFunc(data){
      const targetElement = document.querySelector(data);
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  },
  created() {
    if(screen.width <= 678){
      this.is_mobile = true;
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.get_user_info;
    }
  }
}
</script>
