import {SecondAPIInstance} from "@/plugins/axios";
export const GET_AUDIENCE_ANALYTICS = "GET_AUDIENCE_ANALYTICS";

const state = {

}

const actions = {
  [GET_AUDIENCE_ANALYTICS]:async ()=>{
    const { data }  = await SecondAPIInstance({url:'accounts/audience_analytics/',method:'GET'});
    return data;
  },
}

const mutations = {

}

const getters = {

}
export default {
  state,
  actions,
  mutations,
  getters
}
