import {ChainsAPIInstance} from "@/plugins/axios";
export const GET_TRANSACTION_INFO = "GET_TRANSACTION_INFO";
export const GET_LABELS = "GET_LABELS";

const state = {

}

const actions = {
  [GET_TRANSACTION_INFO]:async (store, info)=>{
    const { data }  = await ChainsAPIInstance({url:'/transaction/'+info.chain+'/'+info.hash,method:'GET'});
    return data;
  },
  [GET_LABELS]:async (store, addresses)=>{
    const { data }  = await ChainsAPIInstance({url:'/labels/'+addresses,method:'GET'});
    return data;
  },
}

const mutations = {

}

const getters = {

}
export default {
  state,
  actions,
  mutations,
  getters
}
