<template>
  <div>
    <a class="logo" href="/"><img src="/faviconnew.png" alt=""><img src="@/assets/img/logo-without-icon.png" alt=""></a>
    <ul class="menu">
      <li>
        <a :class="this.activeNav === '7'?'active-nav':''" href="/cabinet/custom-reports"><i class="bi bi-briefcase"></i><span>My Portfolio tracker</span></a>
      </li>
      <li>
        <a :class="this.activeNav === '4'?'active-nav':''" href="/cabinet/alerts"><i class="bi-bell"></i><span>My Alerts</span></a>
      </li>
      <li>
        <a :class="this.activeNav === '3'?'active-nav':''" href="/cabinet/watchlist"><i class="bi-heart"></i><span>My watchlist</span></a>
      </li>
      <li>
        <a :class="this.activeNav === '2'?'active-nav':''" href="/cabinet/account"><i class="bi-person"></i><span>Account settings</span></a>
      </li>
      <li>
        <a :class="this.activeNav === '6'?'active-nav':''" href="/cabinet/plans"><i class="bi bi-stars"></i><span>Subscription</span></a>
      </li>
    </ul>
    <p class="copyright">Oraclus v1.0</p>
  </div>
</template>
<script>
export default {
  data:()=>({
    activeNav: '',
  }),
  watch: {
    '$route'() {
      this.activeNav = this.$route.meta.activeNav;
    }
  },
  created() {
    this.activeNav = this.$route.meta.activeNav;
  },
}
</script>
