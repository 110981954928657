import {NetflowAPIInstance} from "@/plugins/axios";
export const DASHBOARD_GET_PRICES = "DASHBOARD_GET_PRICES";
export const DASHBOARD_NETFLOW_OPTIONS = "DASHBOARD_NETFLOW_OPTIONS";
export const GET_NETFLOW_REPORT = "GET_NETFLOW_REPORT";

const state = {

}

const actions = {
  [DASHBOARD_GET_PRICES]:async (store, array_data)=>{
    const { data }  = await NetflowAPIInstance({url:'/api/assets/prices/'+array_data[0]+'/?period='+array_data[1],method:'GET'});
    return data;
  },
  [DASHBOARD_NETFLOW_OPTIONS]:async ()=>{
    const { data }  = await NetflowAPIInstance({url:'/api/netflow/options/',method:'GET'});
    return data;
  },
  [GET_NETFLOW_REPORT]:async (store, array_data)=>{
    let form_param = '';
    if(array_data[0].project !== ''){
      form_param = '/exchange/'+array_data[0].project+'/';
    }
    if(array_data[0].top !== ''){
      form_param = '/top_holder/'+array_data[0].top+'/';
    }
    const { data }  = await NetflowAPIInstance({url:'/api/netflow/report/'+array_data[0].chain+'/'+array_data[0].asset+form_param+'?period='+array_data[1],method:'GET'});
    return data;
  }
}

const mutations = {

}

const getters = {

}
export default {
  state,
  actions,
  mutations,
  getters
}
