import {DefaultAPIInstance} from "@/plugins/axios";
export const SEND_PAYPAL_DATA = "SEND_PAYPAL_DATA";
export const AUTO_PAYMENT_TOGGLE = "AUTO_PAYMENT_TOGGLE";
export const ENTERPRISE_PLAN_SUBMIT = "ENTERPRISE_PLAN_SUBMIT";

const state = {

}

const actions = {
  [SEND_PAYPAL_DATA]:async (store, paypal_data)=>{
    const { data }  = await DefaultAPIInstance({url:'/subscriptions/transactions/',method:'POST', data: paypal_data});
    return data;
  },
  [AUTO_PAYMENT_TOGGLE]:async (store, paypal_data)=>{
    const { data }  = await DefaultAPIInstance({url:'/subscriptions/transactions/',method:'POST', data: paypal_data});
    return data;
  },
  [ENTERPRISE_PLAN_SUBMIT]:async (store, form)=>{
    const { data }  = await DefaultAPIInstance({url:'/subscriptions/inquiry/enterprise/',method:'POST', data: form});
    return data;
  },
}

const mutations = {

}

const getters = {

}
export default {
  state,
  actions,
  mutations,
  getters
}
