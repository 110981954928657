import {SecondAPIInstance, ChainsAPIInstance} from "@/plugins/axios";
export const SEARCH_BY_ADDRESS = "SEARCH_BY_ADDRESS";
export const SEARCH_ENS_LABEL_CLOUD = "SEARCH_ENS_LABEL_CLOUD";
export const CHECK_BLOCKCHAINS = "CHECK_BLOCKCHAINS";
export const CSV_DOWNLOAD = "CSV_DOWNLOAD";

const state = {

}

const actions = {
  [SEARCH_BY_ADDRESS]:async (store, search_data)=>{
    const { data }  = await ChainsAPIInstance({url:'/address/'+search_data.chain_slug+'/'+search_data.address,method:'GET'});
    return data;
  },
  [SEARCH_ENS_LABEL_CLOUD]:async (store, search_input)=>{
    const { data }  = await SecondAPIInstance({url:'/accounts/prediction/'+search_input+'/20/',method:'GET'});
    return data;
  },
  [CHECK_BLOCKCHAINS]:async (store, search_input)=>{
    const { data }  = await ChainsAPIInstance({url:'/search/'+search_input,method:'GET'});
    return data;
  },
  [CSV_DOWNLOAD]:async (store, csv_data)=>{
    const { data }  = await ChainsAPIInstance({url:'/transfers/'+csv_data.chain+'/'+csv_data.address,method:'GET'});
    return data;
  },
}

const mutations = {

}

const getters = {

}
export default {
  state,
  actions,
  mutations,
  getters
}
