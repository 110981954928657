<template>
  <div class="linkButton profileMobileClass">
    <el-avatar v-if="!userInfo" @click="profile_menu=!profile_menu"><i class="bi-person-circle"></i></el-avatar>
    <el-avatar class="authUserAvatar" @click="profile_menu=!profile_menu" v-if="userInfo">{{ userInfo.charAt(0) }}
      <ActivePlanComponent/>
    </el-avatar>
    <div class="profileMenu" v-if="profile_menu">
      <p class="email">{{ userInfo || 'Account' }}</p>
      <ul class="menu" v-if="userInfo">
        <li>
          <a :class="this.activeNav === '7'?'active-nav':''" href="/cabinet/custom-reports"><i class="bi bi-briefcase"></i> My Portfolio tracker</a>
        </li>
        <li>
          <a :class="this.activeNav === '4'?'active-nav':''" href="/cabinet/alerts"><i class="bi-bell"></i> My Alerts</a>
        </li>
        <li>
          <a :class="this.activeNav === '3'?'active-nav':''" href="/cabinet/watchlist"><i class="bi-heart"></i> My watchlist</a>
        </li>
        <li>
          <a :class="this.activeNav === '2'?'active-nav':''" href="/cabinet/account"><i class="bi-person"></i> Account settings</a>
        </li>
        <li>
          <a :class="this.activeNav === '6'?'active-nav':''" href="/cabinet/plans"><i class="bi bi-stars"></i> Subscription</a>
        </li>
        <li>
          <LogoutComponent></LogoutComponent>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import LogoutComponent from "@/views/cabinet-components/LogoutComponent"
  import ActivePlanComponent from "@/views/default-components/header-components/ActivePlanComponent";
  export default {
    components:{
      LogoutComponent,
      ActivePlanComponent
    },
    props:{
      email:{
        type: String,
        default: ''
      }
    },
    data: () => ({
      profile_menu: false,
      activeNav: ''
    }),
    watch: {
      '$route'() {
        this.profile_menu = false;
        this.activeNav = this.$route.meta.activeNav;
      }
    },
    created() {
      this.activeNav = this.$route.meta.activeNav;
      let that = this;
      document.addEventListener('click', function (el) {
        if(!el.target.offsetParent.className.includes('profileMobileClass') && !el.target.offsetParent.className.includes('el-avatar--circle')){
          if(that.profile_menu === true){
            that.profile_menu = false;
          }
        }
      });
    },
    computed: {
      userInfo() {
        return this.$store.getters.get_user_info;
      }
    }
  }
</script>
